<template>
	<layoutSection>
		<div class="googleReviewsWidget space-y-32 py-16 tablet:py-0">
			<h2 class="font-bold text-32 px-16 tablet:px-0">{{ title }}</h2>
			<div :class="`googleReviewsWidgetContent-${variant}`">
				<client-only>
					<div data-elfsight-app-lazy :class="widgetClass">
						<div class="googleReviewsWidgetSkeleton">
							<div class="h-[112px] bg-goGrayBrighter animate-pulse rounded-lg mb-20" />
							<div class="googleReviewsWidgetSkeletonGrid">
								<div v-for="col in variant === 'grid' ? 15 : 5" :key="col" class="h-[268px] bg-goGrayBrighter animate-pulse rounded-lg" />
							</div>
						</div>
					</div>
				</client-only>
			</div>
		</div>
	</layoutSection>
</template>

<script>
import layoutSection from '~/components/layoutSection.vue'

export default {
	components: {
		layoutSection
	},
	props: {
		locationWidgetId: {
			type: String,
			default: null,
		},
		variant: {
			type: String,
			default: 'carousel',
			validator: (theme) => ["carousel", "grid"].includes(theme),
		},
		title: {
			type: String,
			default: 'What our customers have to say',
		},
	},
	computed: {
		widgetClass() {
			const id = this.locationWidgetId ? this.locationWidgetId
				: this.variant === 'grid' ? 'a6a5f46b-cf80-407a-9fba-f24daef6978f'
				: '48692000-de91-48c0-a262-6f235ca4189e'
			return `elfsight-app-${id}`
		}
	},
}
</script>

<style lang="scss">
.googleReviewsWidget {
	overflow: hidden;
	.es-header-container,
	.es-review-background-container {
		border: 1px solid theme('colors.goGrayBrighter');
	}
	.es-header-write-review-button,
	.es-load-more-button {
		font-family: theme('fontFamily.bold') !important;
	}
	.es-rating-value {
		font-size: 24px;
	}
	.es-header-rating-reviews-count {
		font-size: 14px;
		color: theme('colors.goGrayDarker');
	}
}
.googleReviewsWidgetContent {
	&-carousel {
		min-height: 430px;
	}
	&-grid {
		min-height: 1031px;
	}
}
$skeletonResponsive: (
	1311px: 4,
	1055px: 3,
	815px: 2,
	511px: 1
);
.googleReviewsWidgetSkeletonGrid {
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(5, 1fr);
	@each $breakpoint, $cols in $skeletonResponsive {
		@media (max-width: #{$breakpoint}) {
			grid-template-columns: repeat(#{$cols}, 1fr);
			.googleReviewsWidgetContent-carousel & > div:nth-child(#{$cols + 1}) {
				display: none;
			}
			.googleReviewsWidgetContent-grid & > div:nth-child(#{$cols * 3 + 1}),
			.googleReviewsWidgetContent-grid & > div:nth-child(#{$cols * 3 + 2}),
			.googleReviewsWidgetContent-grid & > div:nth-child(#{$cols * 3 + 3}) {
				display: none;
			}
		}
	}
}
@media (max-width: theme('screens.tablet')) {
	.googleReviewsWidgetSkeleton,
	.es-header-component-top,
	.es-grid-layout,
	.swiper {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}
}
</style>
